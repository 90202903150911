<template>
  <div class="zhengxie">
    <!-- 通知 -->
    <div>
      <router-link to="/zhengxie/meeting">
        <van-notice-bar
        left-icon="tv-o"
        color="#1989fa"
        background="#ecf9ff"
        mode="closeable"
        >会议视频正在进行,点击进入!</van-notice-bar
      >
      </router-link>
    </div>
    <!-- 背景 -->
    <div>
      <img src="../../assets/yiyang.jpg" alt="" class="bg clearfix">
    </div>
    <div></div>
    <!-- 宫格 -->
    <div class="gridlist" style="margin-top:120px">
      <van-grid style="padding:10px 0px" :border="false">
        <van-grid-item v-for="item in conterlist" :key="item.value" to="/zhengxie/meeting">
          <img src="../../assets/xuqiu.png" alt="" />
          <div style="padding-top: 12px; font-size: 14px">
            {{ item.Name }}
          </div>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 公告 -->
    <div class="gridlist">
      <van-notice-bar
        left-icon="volume-o"
        text="【平台通知】 宜阳县智慧政协平台体验版已上线! 欢迎各位领导与工作人员体验使用。"
      />
    </div>
    <!-- 新闻动态 -->
    <div class="gridlist">
      <div
        style="
          padding: 10px 0px;
          position: relative;
          font-size: 18px;
          width: 100%;
        "
      >
        <span class="dot"></span>
        <span>新闻动态</span>
        <span class="title_right"
          >更多<van-icon name="arrow" class="icon_right"
        /></span>
      </div>
       <div class="center"
        v-for="item in NoticeList"
        :key="item.index">
        <div @click="gotoUrl(item.Id)">
          <!-- <router-link to="'/pension/zsXiangQing/' + item.Id"> -->
          <!-- <div class="centerTitle van-ellipsis">{{ item.Title }}</div> -->
          <div class="Content">
            <div style="display: inline-block"
              class="ContentA">
              <img v-if="item.CoverImgUrl"
                :src="item.CoverImgUrl"
                alt="" />
            </div>
            <div style="display: inline-block"
              class="ContentB">
              <span class="van-multi-ellipsis--l3"
                style="font-size:16px;color:black;">{{ item.Title }}</span>
              <br />
              <span class="centertime">{{ item.AddTime }}</span>
            </div>
          </div>
          <!-- </router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      conterlist: [
        { value: 1, Name: "通讯录" },
        { value: 2, Name: "提案管理" },
        { value: 3, Name: "履职管理" },
        { value: 4, Name: "会议活动" },
        { value: 5, Name: "社情民意" },
        { value: 6, Name: "远程协商" },
        { value: 7, Name: "网络议政" },
        { value: 8, Name: "更多" },
      ],
      NoticeList:[
        {Title:"宜阳县政协专题调研县域体育产业发展",AddTime:"2021-08-05 19:42",CoverImgUrl:"https://lycqljdapi.dexian.ren//upload/images/2021/7/38396507-2b1.jpg",Id:1},
        {Title:"宜阳县政协专题调研县域体育产业发展",AddTime:"2021-08-05 19:42",CoverImgUrl:"https://lycqljdapi.dexian.ren//upload/images/2021/7/38396507-2b1.jpg",Id:1},
        {Title:"宜阳县政协专题调研县域体育产业发展",AddTime:"2021-08-05 19:42",CoverImgUrl:"https://lycqljdapi.dexian.ren//upload/images/2021/7/38396507-2b1.jpg",Id:1},
        {Title:"宜阳县政协专题调研县域体育产业发展",AddTime:"2021-08-05 19:42",CoverImgUrl:"https://lycqljdapi.dexian.ren//upload/images/2021/7/38396507-2b1.jpg",Id:1},
      ]
    };
  },
};
</script>
<style scoped>
.bg {
  position: absolute;
  top: 40px;
  width: 100%;
}
.zhengxie {
  background: #eceff0;
}
.gridlist {
  width: 95%;
  margin: 10px 2.5%;
  background: #fff;
  border-radius: 8px;
}
.gridlist img {
  width: 60%;
}
.gridlist .van-notice-bar {
  border-radius: 8px;
  background: #fff;
}
.dot {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 4px;
  height: 18px;
  border-radius: 3px;
  background: #e91318;
  margin: 0px 10px 0px 10px;
}
.title_right {
  color: #7d7d7d;
  position: absolute;
  right: 15px;
  font-size: 14px;
}
.icon_right {
  position: relative;
  right: 0px;
  top: 2px;
}
.center {
  width: 90%;
  margin: 3px 5%;
}
</style>